import React from "react"

import "../styles/index.scss"

const IndexPage = () => (
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div className="has-text-centered">
          <h1
            className="title is-family-handwrite"
            style={{ fontSize: "5rem" }}
          >
            Mother of Johns
          </h1>
        </div>
      </div>
    </div>
  </section>
)

export default IndexPage
